
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ConfirmInput from '@/components/organisms/dms/common/ConfirmInput.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import ToPageTop from '@/components/organisms/dms/common/ToPageTop.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { AuthorInfo, ContactInfo } from '@/data/dms/dmsData'
import { postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'AuthorConfirm',
  components: {
    ConfirmInput,
    AppButton,
    ToPageTop,
    DssIcon,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const authorInfoData = store.getters.collectAuthorData.authorInfo ? ref(store.getters.collectAuthorData.authorInfo) : ref<AuthorInfo>({
      authorId: '',
      authorName: '',
      authorInfo: '',
      infoSource: '',
    })
    const contactInfoData = store.getters.collectAuthorData.contactInfo ? ref(store.getters.collectAuthorData.contactInfo) : ref<ContactInfo>({
      name: '',
      mailAddress: '',
      address: '',
      phoneNumber: '',
    })

    const resetCollectAuthorData = async () => {
      await store.dispatch('setCollectAuthorData', {})
    }

    return {
      router,
      authorInfoData,
      contactInfoData,

      async send () {
        const url = `${BASE_URL}/outer/inquiry/provide`
        const body = JSON.stringify({
          ...authorInfoData.value,
          ...contactInfoData.value,
        })
        console.log(body)
        const response = await postDataCredential(url, body, true)
        if (response.status === 200) {
          await resetCollectAuthorData()
          await router.push('complete')
        }
        await router.push('/dms/author/complete')
      },
    }
  },
})
