
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import AuthorConfirm from '@/components/organisms/dms/AuthorConfirm/AuthorConfirm.vue'
import StopSend from '@/components/organisms/dms/common/StopSend.vue'

export default defineComponent({
  components: {
    AuthorConfirm,
    StopSend,
  },
  setup () {
    const router = useRouter()
    return {
      router,
    }
  },
})
